async function client(
    endpoint,
    {body, ...customConfig} = {},
    blob = false) {
    let apiURL = 'https://vestcargo.abvsoft.ro/api'
    let requestType = {};
    const token = localStorage.getItem('token');
    const headers = {
        "Content-Type": "application/json",
    };
    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    const config = {
        method: customConfig.method,
        ...customConfig,
        headers: {
            ...headers,
            ...customConfig.headers,
        },
    };

    if (body) {
        config.body = JSON.stringify(body);
    }

    if (config.headers["Content-Type"] && config.headers["Content-Type"] === 'multipart/form-data') {
        delete config.headers["Content-Type"];
        config.body = body;
    }

    if (endpoint.includes('images')) {
        requestType = 'image';
    }

    const response = await window.fetch(`${apiURL}${endpoint}`, config);

    if (response.status === 401) {
        localStorage.removeItem('token');
        window.location = '/login';
    }

    if (requestType === 'image' || blob) {
        if (response.ok){
            return await response.blob();

        } else {
            const data = await response.json();

            return Promise.reject(data);
        }
    }

    const data = await response.json().then((result) => {
        if (response.ok) {
            if (result?.errors && result.errors[0] !== null) {
                return Promise.reject(result);
            }
        }
        if (!response.ok) {
            return Promise.reject(result);
        }

        return result;
    }).catch((err) => {
        return Promise.reject(err);
    });

    if (response.ok) {
        return Promise.resolve(data);
    }
}

export function get(url, blob = false) {
    return client(url, {
        method: "GET",
    }, blob);
}

export function post(url, data, customHeaders) {
    return client(url, {
        method: "POST",
        body: data,
        headers: customHeaders
    });
}

export function put(url, data) {
    return client(url, {
        method: "PUT",
        body: data,
    });
}

export function remove(url, data = undefined) {
    return client(`${url}`, {body: data, method: "DELETE"});
}

export function patch(url, data) {
    return client(`${url}`, {method: "PATCH", body: data});
}