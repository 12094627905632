import KarimSignature from "../../assets/karim.jpg";
import {Document, Image, Page, StyleSheet, Text, View} from "@react-pdf/renderer";

interface Props {
  invoiceDetails: any;
}

const styles
  = StyleSheet.create({
  borderText: {},
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '90%',
    marginHorizontal: 'auto',
    marginTop: 20,
    border: '5px solid gray',
    paddingVertical: 5
  },
  textNormal: {
    paddingVertical: 3,
    fontSize: 10,
    fontWeight: "thin",
    fontFamily: "Roboto"
  },
  textNormalNoPadding: {
    fontSize: 10,
    fontWeight: "thin",
    fontFamily: "Roboto"
  },
  textBoldNoPadding: {
    fontSize: 10,
    fontWeight: 800,
    fontFamily: 'Roboto-Bold',
  },
  borderTextBold: {
    fontSize: 10,
    fontWeight: 800,
    fontFamily: 'Roboto-Bold',
    paddingVertical: 1
  },
  logo: {
    display: 'flex', justifyContent: 'center', alignItems: 'center', width: '27%'
  },
  flex: {
    display: 'flex'
  },
  signature: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '15%',
    marginLeft: 20,
    marginTop: 20,
    marginBottom: 20
  },
  borderColumn: {
    width: '40%', border: '0.5px solid black'
  },
  borderValue: {
    width: '60%',
    border: '0.5px solid black'
  },
  title: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10
  },
  titleText: {
    fontFamily: 'Times-Bold',
    fontWeight: 'bold',
    fontSize: 20
  },
  clientInformation: {
    flexDirection: 'column',
    width: '90%',
    marginTop: 10,
    marginBottom: 20,
    marginHorizontal: 'auto'
  },
  pageContainer: {
    maxHeight: '100%',
    flexDirection: 'column',
    width: '90%',
    paddingTop: 20,
    paddingBottom: 120
  },
  centered: {},
  truckDetails: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    marginVertical: 15,
    marginHorizontal: 'auto',
    flexGrow: 0.5
  },
  transportInfo: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    marginLeft: 30,
    marginRight: 10,
  },
  footerSignature: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '90%',
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
  },
  importantText: {width: '90%', marginLeft: 30, marginVertical: 10},
  footerContainer: {width: '90%', margin: 20, flexGrow: 1}
});

const TmsTransportTemplate = (props: Props) => {
  const dateDiff = (date_from: string, date_to: string) => {
    // return new Date(date_fro)
    const parsedFrom = new Date(date_from).setHours(0, 0, 0, 0);
    const parsedTo = new Date(date_to).setHours(0, 0, 0, 0);

    return parsedFrom.valueOf() !== parsedTo.valueOf();
  }
  console.log(props,"test")
  return (
    <Document style={{display: 'none'}}>
      <Page size='A4' wrap style={styles.pageContainer}>

        {/* <View style={styles.header}>

          <Image style={{width: '30%', height: '100%', margin: 'auto'}} src={KarimLogo}/>

          <View style={{marginLeft: 10, width: '50%'}}>
            <Text style={styles.textBoldNoPadding}>Karim Spedition Logistik S.R.L.</Text>
            <View style={styles.flex}>
              <Text style={styles.textBoldNoPadding}>CUI:RO 42581537, J5/845/2020</Text>
            </View>
            <Text style={styles.textBoldNoPadding}>Strada Sovata 35, PB15, ap.4, Oradea, Bihor,
              Romania</Text>
            <View style={styles.flex}>
              <Text style={styles.textBoldNoPadding}>Mobil:+40757574981 / +40751325311 </Text>
            </View>
            <View style={styles.flex}>
              <Text style={styles.textBoldNoPadding}>e-mail: office@karim-spedition.com</Text>
            </View>
          </View>

        </View> */}

        <View style={styles.title}>
          <Text style={styles.titleText}>Comanda de transport
            nr. {props.invoiceDetails?.order_number} din {new Date(props.invoiceDetails?.created_at_utc).toLocaleDateString('ro-RO')}</Text>
        </View>

        <View style={styles.clientInformation}>
          <View style={{display: 'flex', flexDirection: 'row'}}>
            <View style={styles.borderColumn}>
              <Text style={styles.borderTextBold}>Transportator:</Text>
            </View>
            <View style={styles.borderValue}>
              <Text style={styles.textNormal}>
                {props?.invoiceDetails?.transporter?.name}
              </Text>
            </View>
          </View>
          <View style={{display: 'flex', flexDirection: 'row'}}>
            <View style={styles.borderColumn}>
              <Text style={styles.borderTextBold}>CUI
                :</Text>
            </View>
            <View style={styles.borderValue}>
              <Text style={styles.textNormal}>
                {props?.invoiceDetails?.transporter?.unique_identification_number}
              </Text>
            </View>
          </View>
          <View style={{display: 'flex', flexDirection: 'row'}}>
            <View style={styles.borderColumn}>
              <Text style={styles.borderTextBold}>ADRESA:</Text>
            </View>
            <View style={styles.borderValue}>
              <Text style={styles.textNormal}>
                {props?.invoiceDetails?.transporter?.address}
              </Text>
            </View>
          </View>
          <View style={{display: 'flex', flexDirection: 'row'}}>
            <View style={styles.borderColumn}>
              <Text style={styles.borderTextBold}>PERSOANA DE CONTACT:</Text>
            </View>
            <View style={styles.borderValue}>
              <Text
                style={styles.textNormal}>{`${props?.invoiceDetails?.transporter_contact || '-'}`}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.transportInfo}>
          <Text style={{
            fontFamily: 'Roboto',
            fontSize: 12
          }}>DETALII CAMION / MARFA</Text>

          <Text style={styles.textBoldNoPadding}>Marfa : {props.invoiceDetails?.package_info}</Text>
          <Text style={styles.textBoldNoPadding}>Nr. auto
            : {props?.invoiceDetails?.car_reg_number || '-'}</Text>
          <Text style={styles.textBoldNoPadding}>Sofer / Tel.
            Sofer: {props?.invoiceDetails?.driver_info || '-'}</Text>
          <Text
            style={styles.textBoldNoPadding}>Observatii: {props?.invoiceDetails?.remarks_transporter}</Text>
        </View>

        {props?.invoiceDetails?.OrderDetails?.map((orderDetailsItem: any) => (
          <>
            {orderDetailsItem.type === 'PICKUP' &&
                <View style={styles.truckDetails} wrap={false}>
                    <Text style={{
                      fontFamily: 'Roboto',
                      fontSize: 16,
                      marginLeft: 30
                    }}>Incarcare</Text>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Data
                                / Ora
                                :</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {`${new Date(orderDetailsItem.date_from).toLocaleDateString('RO-ro')}${dateDiff(orderDetailsItem.date_from, orderDetailsItem.date_to) ? `/ ${new Date(orderDetailsItem.date_to).toLocaleDateString('RO-ro')}` : ''} `}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Firma
                                :</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem?.company}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Adresa
                                :</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem?.address}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Judet
                                :</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem?.county}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Oras
                                :</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem?.city}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Metri podea
                                :</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem?.metri_podea_patrati}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Tonaj
                                :</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem?.tonnage}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Observatii:</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem.remarks || '-'}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Referinta:</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem?.reference || '-'}
                            </Text>
                        </View>
                    </View>
                </View>
            }

            {orderDetailsItem.type === 'DROPOFF' &&
                <View style={styles.truckDetails} wrap={false}>
                    <Text style={{
                      fontFamily: 'Roboto',
                      fontSize: 16,
                      marginLeft: 30
                    }}>Descarcare</Text>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Data / Ora :</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {`${new Date(orderDetailsItem.date_from).toLocaleDateString('RO-ro')}${dateDiff(orderDetailsItem.date_from, orderDetailsItem.date_to) ? `/ ${new Date(orderDetailsItem.date_to).toLocaleDateString('RO-ro')}` : ''} `}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Firma :</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem?.company}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Adresa :</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem?.address}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Adresa
                                :</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem?.address}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Oras
                                :</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem?.city}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Metri podea
                                :</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem?.metri_podea_patrati}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Tonaj
                                :</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem?.tonnage}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Observatii:</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem.remarks}
                            </Text>
                        </View>
                    </View>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={styles.borderColumn}>
                            <Text style={styles.borderTextBold}>Referinta:</Text>
                        </View>
                        <View style={styles.borderValue}>
                            <Text style={styles.textNormal}>
                              {orderDetailsItem?.reference || '-'}
                            </Text>
                        </View>
                    </View>
                </View>
            }
          </>
        ))}

        {/* <View>
          <View style={styles.importantText}>
            <Text style={{
              fontFamily: 'Times-Bold',
              fontSize: '16'
            }}>Pret
              convenit: {parseFloat((Number(props.invoiceDetails?.transporter_price).toFixed(2)))}
              {' ' + props.invoiceDetails.transporter_currency}
              {props.invoiceDetails?.transporter_vat > 0 ? ' + TVA' : ''} </Text>
          </View>

        </View> */}

        {/* <Image style={{position: 'absolute', bottom: 30, marginLeft: 30, width: '16%', height: '12%'}} fixed
               src={KarimSignature}/> */}
      </Page>
    </Document>


  )
}

export default TmsTransportTemplate;
