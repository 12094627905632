import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import * as api from "../../api/api-client";
import {FieldValues, useForm} from "react-hook-form";
import {Box, Button, Card, Container, Toolbar} from "@mui/material";
import BasicFormControl from "../../components/form-helpers/BasicFormControl";
import AutocompleteFormControl from "../../components/form-helpers/AutocompleteFormControl";
import DatePickerFormControl from "../../components/form-helpers/DatePickerFormControl";
import {toast} from "react-toastify";
import SelectFormControl from "../../components/form-helpers/SelectFormControl";
import {v4} from "uuid";
import Table from "../../components/table/Table";
import DialogContainer from "../../components/DialogContainer";
import CreateOrderDetailsForm from "../../components/forms/CreateOrderDetailsForm";

const OrderDetailsPage = () => {
  const [order, setOrder] = useState<any>(undefined);
  const [orderDetails, setOrderDetails] = useState<any>([]);
  const [series, setSeries] = useState<any[]>([{label: 'Selecteaza o serie', value: 'no'}]);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [clients, setClients] = useState<any[]>([]);
  const [transporters, setTransporters] = useState<any[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<any>({});
  const [addOrderDetailsDialogOpen, setAddOrderDetailsDialogOpen] = useState<boolean>(false);
  const [carRegNumbers, setCarRegNumbers] = useState<string[]>([]);

  const tableHeaders = [
    {
      id: 'type',
      label: 'Tip',
      width: 100
    },
    {
      id: 'company',
      label: 'Firma',
      width: 100
    },
    {
      id: 'address',
      label: 'Adresa',
      width: 200
    },
    {
      id: 'location',
      label: 'Tara',
      width: 100
    },
    {
      id: 'date_from',
      label: 'De la',
      type: 'date',
      width: 100
    },
    {
      id: 'date_to',
      label: 'La',
      type: 'date',
      width: 100
    },
    {
      id: 'metri_podea_patrati',
      label: 'Metri patrati',
      type: 'number',
      width: 100
    },
    {
      id: 'tonnage',
      label: 'Tonaj',
      width: 100
    },
    {
      id: 'reference',
      label: 'Referinta',
      width: 100
    }
  ]

  const actions = [
    {
      name: 'deleteRow',
      displayName: 'Sterge randul',
      action: handleOrderDetailsDelete
    },
    {
      name: 'editRow',
      displayName: 'Modifica randul',
      action: handleOrderDetailsEdit
    }
  ]

  const {id} = useParams();
  const {
    handleSubmit,
    formState: {errors},
    control,
    setValue,
    getValues,
    reset
  } = useForm({
    defaultValues: {
      client: '',
      transporter: '',
      buyer: '',
      series: '',
      number: '',
      driver_info: '',
      car_reg_number: '',
      package_info: '',
      remarks_transporter: '',
      remarks_buyer: '',
      client_price: '',
      client_currency: 'RON',
      transporter_price: '',
      transporter_currency: 'RON',
      user_id: '',
      created_at_utc: '',
      transporter_contact: '',
      client_contact: '',
      client_vat: 0,
      transporter_vat: 0,
      floor_used_in_meters: 0,
      order_number: 0,
    }
  });

  useEffect(() => {
    api.get('/users/series').then((res) => {
      const orderSeries = res.filter((resItem: any) => resItem.invoice_type === 'order');
      setSeries(orderSeries.map((orderSerie: any) => {
        return {label: orderSerie.series, value: orderSerie.series}
      }));
    }).catch((err) => {
      toast.error(err);
    });


  }, []);

  useEffect(() => {
    api.get(`/orders/${id}`).then((res: any) => {

      reset({
        client: res.client_id,
        transporter: res.transporter_id,
        buyer: res.buyer_id,
        series: res.series,
        number: res.number,
        driver_info: res.driver_info,
        car_reg_number: res.car_reg_number,
        package_info: res.package_info,
        remarks_transporter: res.remarks_transporter,
        remarks_buyer: res.remarks_buyer,
        client_price: res.client_price,
        client_currency: res.client_currency,
        transporter_price: res.transporter_price,
        transporter_currency: res.transporter_currency,
        user_id: res.user_id,
        created_at_utc: res.created_at_utc,
        transporter_contact: res.transporter_contact,
        client_contact: res.client_contact,
        client_vat: res.client_vat,
        transporter_vat: res.transporter_vat,
        floor_used_in_meters: res.floor_used_in_meters,
        order_number: res.order_number
      });

      setOrder(res);

      api.get('/autofleet/options').then((res) => {
        let map = res.map((item: { reg_no: any; }) => item.reg_no);
        setCarRegNumbers(map);
      }).catch((err) => {
        toast.error(err);
      })

      const orderDetails = res.OrderDetails?.map((resItem: any) => {
        const orderItem = {
          key: v4(),
          location: resItem.location,
          company: resItem.company,
          address: resItem.address,
          reference: resItem.reference,
          order_id: resItem.order_id,
          date_from: resItem.date_from,
          date_to: resItem.date_to,
          remarks: resItem.remarks,
          order_details_id: resItem.order_details_id,
          type: resItem.type,
          county: resItem.county,
          city: resItem.city,
          metri_podea_patrati: resItem.metri_podea_patrati,
          tonnage: resItem.tonnage
        }

        return orderItem;
      });

      setOrderDetails(orderDetails);
    })
  }, [])

  const toggleEdit = () => {
    setEditMode(!editMode);
  }

  const onBuyerAutocompleteInput = (event: any) => {
    const {value} = event.target;

    if (value.length >= 3) {
      api.get(`/partners/autocomplete?searchKey=${value}`).then((res) => {
        setClients(res);
      }).catch((err) => {
          toast(err, {type: 'error'});
          console.error(err);
        }
      )
    }
  }

  const onBuyerAutocompleteChange = (event: any, value: any) => {
    setValue('buyer', value.partner_id);
  }

  const onTransporterAutocompleteChange = (event: any, value: any) => {
    setValue('transporter', value.partner_id);
  }

  function handleOrderDetailsDelete(row: any) {
    setOrderDetails((prevState: any) => prevState.filter((prevItem: any) => prevItem.key !== row.key));

    api.remove(`/orders/details/${row.order_details_id}`).then((res) => {
      toast.success(res.message);
    }).catch((err) => {
      toast.error(err.message);
    })
  }

  function handleOrderDetailsEdit(row: any) {
    setSelectedOrder(row);
    setAddOrderDetailsDialogOpen(true);
  }

  const handleOrderDetailsDialogState = (dialogState: boolean) => {
    if (!dialogState) {
      setSelectedOrder(undefined);
    }

    setAddOrderDetailsDialogOpen(dialogState);
  }

  const onCarRegNumberChange = (event: any, value: any) => {
    setValue('car_reg_number', value);
  }

  const updateOrder = (data: FieldValues) => {
    data.order_id = order.order_id;
    data.client_id = getValues('client');
    data.transporter_id = getValues('transporter');
    data.buyer_id = getValues('buyer');
    data.rate = JSON.parse(localStorage.getItem('eurRate') || '')?.rate;

    api.put('/orders', data).then((res) => {
      toast.success('Datele comenzii au fost actualizate');
    }).catch((err) => {
      toast.error(err?.message);
    })

  }

  const handleOrderDetailsSubmit = (data: FieldValues) => {
    if (data.key) {
      setOrderDetails((prevState: any) => prevState.map((item: any) => {
        if (item.key === data.key) {
          item = data;
          return item;
        } else {
          return item;
        }
      }));
    } else {
      data.key = v4();
      setOrderDetails((prevState: any) => [...prevState, data]);
      handleOrderDetailsDialogState(false);
    }

    if (selectedOrder && Object.keys(selectedOrder).length > 0) {
      data.order_details_id = selectedOrder.order_details_id;

      api.put(`/orders/details/${data.order_details_id}`, data).then((res) => {
        toast.success('Detaliile comenzii au fost actualizate');
      }).catch((err) => {
        toast.error(err?.message);
      })

      handleOrderDetailsDialogState(false);
      setSelectedOrder(undefined);
    } else {
      data.order_id = order.order_id;

      api.post(`/orders/details/${data.order_id}`, data).then((res) => {
        toast.success('Detaliile comenzii au fost adaugate.');

        handleOrderDetailsDialogState(false);
      }).catch((err) => {
        console.error(err.message);
      });
    }
  }

  return (
    <Container sx={{height: '100%', margin: 0, marginTop: '3rem', maxWidth: '100vw !important'}}>
      <Toolbar sx={{width: '100%', paddingLeft: '0px !important', paddingRight: '0px !important'}}>
        <Card sx={{width: '100%'}}>
          <Button onClick={toggleEdit}>Editare</Button>
        </Card>
      </Toolbar>
      <Box>
        <>
          {
            <form onSubmit={handleSubmit(updateOrder)}>

              {order && order.client && <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
                  <AutocompleteFormControl control={control} name="client" displayText="Firma" options={[]}
                                           optionLabelFunc={(partner) => partner?.partner_name}
                                           defaultValue={{
                                             partner_id: order.client.partner_id,
                                             partner_name: order.client.name
                                           } || ""} disabled={true}
                  />
                  <AutocompleteFormControl control={control} name="buyer" displayText="Client" options={clients}
                                           defaultValue={{
                                             partner_id: order.buyer.partner_id,
                                             partner_name: order.buyer.name
                                           } || ""}
                                           optionLabelFunc={(clients) => clients?.partner_name}
                                           onChangeHandler={onBuyerAutocompleteChange}
                                           onInputHandler={onBuyerAutocompleteInput}
                                           disabled={!editMode}
                  />
                  <AutocompleteFormControl control={control}
                                           name='transporter'
                                           defaultValue={{
                                             partner_id: order?.transporter?.partner_id,
                                             partner_name: order?.transporter?.name
                                           } || ""}
                                           displayText='Transportator'
                                           options={clients}
                                           optionLabelFunc={(clients) => clients?.partner_name || ""}
                                           onChangeHandler={onTransporterAutocompleteChange}
                                           onInputHandler={onBuyerAutocompleteInput}
                                           disabled={!editMode}
                  />

              </Box>}

              <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
                <SelectFormControl control={control}
                                   name='series'
                                   selectOptions={series}
                                   displayText='Serie'
                                   rules={{required: {value: true, message: 'Seria este obligatorie'}}}
                                   disabled={!editMode}
                                   errors={errors}
                />

                <BasicFormControl control={control} name='number' displayText='Numar' disabled={!editMode}/>

                <DatePickerFormControl control={control}
                                       name='created_at_utc'
                                       displayText='Data creare'
                                       rules={{
                                         required: {value: true, message: 'Data creare este obligatorie.'}
                                       }}
                                       errors={errors}
                                       disabled={!editMode}
                />
              </Box>


              <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
                <BasicFormControl control={control} name='driver_info' displayText='Informatii sofer'
                                  disabled={!editMode}/>
                {order && <AutocompleteFormControl control={control}
                                         name='car_reg_number'
                                         displayText='Numar de inmatriculare'
                                         options={carRegNumbers}
                                         optionLabelFunc={(carRegNumber) => {
                                           return carRegNumber;
                                         }}
                                         onChangeHandler={onCarRegNumberChange}
                                         defaultValue={ order.car_reg_number}
                                         disabled={!editMode}

                />}

                <BasicFormControl control={control} name='package_info' displayText='Detalii marfa'
                                  disabled={!editMode}/>
              </Box>
              
              <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
                <BasicFormControl control={control} name='client_price'
                                  displayText='Pret client'
                                  disabled={!editMode}
                                  hint='Pentru pretul in lei se calculeaza automat TVA'
                                  type='number'
                                  rules={{required: {value: true, message: 'Pretul client este obligatoriu'}}}
                                  errors={errors}/>

               

                <SelectFormControl control={control} name='client_currency'
                                   selectOptions={[{label: 'EURO', value: 'EUR'}, {label: 'RON', value: 'RON'}]}
                                   displayText='Valuta'
                                   disabled={!editMode}
                                   rules={{required: {value: true, message: 'Valuta este obligatorie'}}}
                                   errors={errors}/>

                <BasicFormControl control={control} name='client_vat' displayText='TVA Client (%)' type='number'
                                  disabled={!editMode}/>
              </Box>
              {/*<Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>*/}
              {/*  <BasicFormControl control={control} name='transporter_price'*/}
              {/*                    displayText='Pret transportator'*/}
              {/*                    hint='Pentru pretul in lei se calculeaza automat TVA'*/}
              {/*                    type='number'*/}
              {/*                    disabled={!editMode}*/}
              {/*                    rules={{required: {value: true, message: 'Pretul transportator este obligatoriu'}}}*/}
              {/*                    errors={errors}/>*/}

              {/*  <SelectFormControl control={control} name='transporter_currency'*/}
              {/*                     selectOptions={[{label: 'EURO', value: 'EUR'}, {label: 'RON', value: 'RON'}]}*/}
              {/*                     displayText='Valuta'*/}
              {/*                     disabled={!editMode}*/}
              {/*                     rules={{required: {value: true, message: 'Valuta este obligatorie'}}}*/}
              {/*                     errors={errors}/>*/}

              {/*  <BasicFormControl control={control} name='transporter_vat' displayText='TVA Transportator (%)'*/}
              {/*                    type='number' disabled={!editMode}/>*/}
              {/*</Box>*/}

              <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
                <BasicFormControl control={control} name='order_number'
                                  displayText='Numar comanda'
                                  disabled={!editMode}
                                  type='string'
                                  errors={errors}/>
                <BasicFormControl control={control} name='floor_used_in_meters'
                                  displayText='Metri de podea preluare'
                                  type='number'
                                  errors={errors}
                                  disabled={true}/>
              </Box>

              <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
                <BasicFormControl control={control} name='remarks_buyer' displayText='Observatii client'
                                  disabled={!editMode}/>
                <BasicFormControl control={control} name='remarks_transporter' displayText='Observatii transportator'
                                  disabled={!editMode}/>
              </Box>

              <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
                <BasicFormControl control={control} name='transporter_contact' displayText='Contact transportator'
                                  disabled={!editMode}/>

                <BasicFormControl control={control} name='client_contact' displayText='Contact client'
                                  disabled={!editMode}/>
              </Box>

              {editMode &&
                  <Button type="submit" variant='contained' sx={{width: '100%'}}>Submit</Button>
              }


              {orderDetails && <Table headers={tableHeaders}
                                      rows={orderDetails}
                                      handleAddActionClick={() => setAddOrderDetailsDialogOpen(true)}
                                      actions={actions}
              />}

            </form>
          }

        </>

        <DialogContainer isDialogOpen={addOrderDetailsDialogOpen} dialogTitle='Adauga o pozitie'
                         handleDialogState={handleOrderDetailsDialogState}>
          <CreateOrderDetailsForm handleOrderDetailsSubmit={handleOrderDetailsSubmit} existingOrder={selectedOrder}
                                  edit={true}/>
        </DialogContainer>
      </Box>

    </Container>
  )
}

export default OrderDetailsPage;