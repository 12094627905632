import {Box, Button} from "@mui/material";
import BasicFormControl from "../form-helpers/BasicFormControl";
import {useEffect, useState} from "react";
import {FieldValues, useForm} from "react-hook-form";
import SelectFormControl from "../form-helpers/SelectFormControl";
import DateTimePickerFormControl from "../form-helpers/DateTimePickerFormControl";
import AutocompleteFormControl from "../form-helpers/AutocompleteFormControl";
import * as api from "../../api/api-client";
import {toast} from "react-toastify";

interface Props {
  handleOrderDetailsSubmit: (data: FieldValues) => void;
  existingOrder?: any;
  edit?: boolean;
  counties?: string[]
}


const CreateOrderDetailsForm = (props: Props) => {
  // const [products, setProducts] = useState<any>([
  //   {key: v4(), name: '', quantity: '', weight: '', unit_of_measure: ''}
  // ]);
  // const [errors, setErrors] = useState<any>([
  //   {key: products?.[0]?.key, quantity: '', weight: ''}
  // ]);

  const {
    handleSubmit,
    control,
    reset,
    formState: {errors},
    setValue,
      getValues
  } = useForm({
    defaultValues: {
      type: props.existingOrder?.type,
      location: props.existingOrder?.location,
      date_from: props.existingOrder?.date_from,
      date_to: props.existingOrder?.date_to,
      remarks: props.existingOrder?.remarks,
      company: props.existingOrder?.company,
      address: props.existingOrder?.address,
      metri_podea_patrati: props.existingOrder?.metri_podea_patrati,
      reference: props.existingOrder?.reference,
      county: props.existingOrder?.county,
      city: props.existingOrder?.city,
      tonnage: props.existingOrder?.tonnage,
    }
  });
  const [counties, setCounties] = useState<string[]>([]);
  const [cities, setCities] = useState<string[]>([]);
  const [freeSolo, setFreeSolo] = useState<boolean>(false);

  useEffect(() => {
    if (props.existingOrder && Object.keys(props.existingOrder).length > 0) {
      reset({
        type: props.existingOrder?.type,
        location: props.existingOrder?.location,
        date_from: props.existingOrder?.date_from,
        date_to: props.existingOrder?.date_to,
        remarks: props.existingOrder?.remarks,
        company: props.existingOrder?.company,
        address: props.existingOrder?.address,
        metri_podea_patrati: props.existingOrder?.metri_podea_patrati,
        reference: props.existingOrder?.reference,
        county: props.existingOrder?.county,
        city: props.existingOrder?.city,
        tonnage: props.existingOrder?.tonnage,
      });

      // setProducts([...props.existingOrder.products]);
      // setErrors(props.existingOrder?.products?.map((product: any) => {
      //   return {
      //     key: product.key,
      //     quantity: '',
      //     weight: ''
      //   }
      // }));
    }

    api.get('/localities/counties').then((res:string[]) => {
      setCounties(["Altul", ...res]);
      if (props.existingOrder && Object.keys(props.existingOrder).length > 0){
        reset({
          county: props.existingOrder?.county,
          city: props.existingOrder?.city,
        });
      }
    }).catch((err) => {
      toast.error(err);
    })
  }, [])

  const onCountyChange = (event: any, value: any) => {
    setValue('county', value);
    if(!value){
      setCities([]);
      return;
    }
    if(value=="Altul"){
        setFreeSolo(true);
    }else{
      setFreeSolo(false);
    }
    api.get('/localities/cities?county=' + value).then((res) => {
      setCities(res);
    }).catch((err) => {
      toast.error(err);
    })
  }

  const onCityChange = (event: any, value: any) => {
    setValue('city', value);
  }
  const onCityInput = (event: any) => {
    console.log(event);
    if (freeSolo) {
      setValue('city', event.target.value);
    }
  }
  // const addProduct = () => {
  //   const key = v4();
  //
  //   setProducts((prevState: any) => ([...prevState, {
  //     key: key,
  //     name: '',
  //     quantity: '',
  //     weight: '',
  //     unit_of_measure: ''
  //   }]));
  //
  //   setErrors((prevState: any) => ([...prevState, {
  //     key: key,
  //     quantity: '',
  //     weight: ''
  //   }]))
  // }

  // const removeProduct = (key: string) => {
  //   const data = [...products];
  //
  //   const productToDelete = data.find((product: any) => product.key === key);
  //
  //   setProducts(data.filter((product: any) => product.key !== key));
  //   setErrors(errors.filter((error: any) => error.key !== key));
  //
  //   if (props.edit) {
  //     api.remove(`/invoices/orders/goods/${productToDelete.order_goods_id}`).then((res) => {
  //       toast.success('Produsul a fost sters');
  //     }).catch((err) => {
  //       toast.error(err);
  //     })
  //   }
  // }

  // const handleProductChange = (event: any, index: number) => {
  //   const data = [...products];
  //   let {name, value} = event.target;
  //
  //   if (name === 'quantity' || name === 'weight') {
  //     if (!(/^(\d+(?:,\d{1,2})?).*/).test(value)) {
  //       errors[index][name] = 'Campul trebuie sa fie un numar';
  //       setErrors(errors);
  //     } else {
  //       errors[index][name] = undefined;
  //       setErrors(errors);
  //     }
  //   }
  //
  //   data[index][name] = value;
  //
  //   setProducts(data);
  // }

  const handleOrderDetailsSubmit = (data: FieldValues) => {
    if (props.existingOrder) {
      data.key = props.existingOrder.key;
    }

    // data.products = products;

    props.handleOrderDetailsSubmit(data);
  }

  return (
    <form onSubmit={handleSubmit(handleOrderDetailsSubmit)}>
      <Box sx={{display: 'flex', flexDirection: 'column'}}>
        <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
          <Box sx={{width: {xs: '100%', md: '96%'}}}>
            <SelectFormControl control={control} name='type' selectOptions={
              [
                {label: 'Preluare', value: 'PICKUP'},
                {label: 'Livrare', value: 'DROPOFF'}
              ]
            } displayText='Tip' rules={{
              required: {value: true, message: 'Campul este obligatoriu'}
            }} errors={errors}/>
          </Box>

        </Box>

        <Box sx={{display: 'flex', width: '100%', flexDirection: {xs: 'column', md: 'row'}}}>
        <AutocompleteFormControl control={control}
                                 name='county'
                                 displayText='Judet'
                                 options={counties}
                                 optionLabelFunc={(county) => (county? county: "")}
                                 onChangeHandler={onCountyChange}
                                 defaultValue={props.existingOrder?.county || ''}
                                 rules={{required: {value: true, message: 'Judetul este obligatoriu'}}}
                                 errors={errors}

        />
        <AutocompleteFormControl control={control}
                                 name='city'
                                 displayText='Oras'
                                 options={cities}
                                 optionLabelFunc={(city) => (city? city: "")}
                                 onInputHandler={onCityInput}
                                 onChangeHandler={onCityChange}
                                 freeSolo={freeSolo}
                                 defaultValue={props.existingOrder?.city || ''}
                                 rules={{required: {value: true, message: 'Orasul este obligatoriu'}}}
                                 errors={errors}

        />
        </Box>

        <Box sx={{display: 'flex', width: '100%', flexDirection: {xs: 'column', md: 'row'}}}>
          <BasicFormControl control={control} name='address'
                            displayText='Adresa'/>
        </Box>

        <Box sx={{display: 'flex', width: '100%', flexDirection: {xs: 'column', md: 'row'}}}>
          <BasicFormControl control={control} name='metri_podea_patrati'
                            displayText='Metri podea patrati' type='number' />
        </Box>

        <Box sx={{display: 'flex', width: '100%', flexDirection: {xs: 'column', md: 'row'}}}>
          <BasicFormControl control={control} name='tonnage'
                            displayText='Tonaj' type='number' />
        </Box>

        <Box sx={{display: 'flex', width: '100%', flexDirection: {xs: 'column', md: 'row'}}}>
          <BasicFormControl control={control} name='company'
                            displayText='Companie'/>


          <BasicFormControl control={control} name='location'
                            displayText='Tara'/>
        </Box>

        <Box sx={{display: 'flex'}}>
          <DateTimePickerFormControl control={control} name='date_from'
                                     displayText='De la'/>
          <DateTimePickerFormControl control={control} name='date_to'
                                     displayText='la'/>
        </Box>

        <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
          <BasicFormControl control={control} name='reference' displayText='Referinta' type=''/>
          <BasicFormControl control={control} name='remarks' displayText='Observatii' type=''/>
        </Box>

        {/*<Box sx={{display: 'flex', flexDirection: 'column', px: 2}}>*/}

        {/*  <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 2}}>*/}
        {/*    <Typography variant='h6'><b>Produse comanda</b></Typography>*/}
        {/*    <Button onClick={addProduct}><PlusOne/></Button>*/}
        {/*  </Box>*/}

        {/*  /!*{products && products.length > 0 && products.map((product: any, index: number) => {*!/*/}
        {/*  /!*  return <Box key={product.key} sx={{display: 'flex', flexDirection: 'column', my: 2}}>*!/*/}
        {/*  /!*    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>*!/*/}
        {/*  /!*      <Typography variant='body2'><b>Produs comanda {index + 1}</b></Typography>*!/*/}
        {/*  /!*      <Button*!/*/}
        {/*  /!*        onClick={() => removeProduct(product.key)}>*!/*/}
        {/*  /!*        <Remove/>*!/*/}
        {/*  /!*      </Button>*!/*/}
        {/*  /!*    </Box>*!/*/}
        {/*  */}
        {/*  /!*    <Box sx={{display: 'flex'}}>*!/*/}
        {/*  /!*      <TextField name='name' label='Nume produs'*!/*/}
        {/*  /!*                 value={product.name}*!/*/}
        {/*  /!*                 onChange={(ev) => handleProductChange(ev, index)} sx={{width: '100%', mx: '1rem'}}/>*!/*/}
        {/*  /!*      <TextField name='quantity' label='Cantitate' value={product.quantity}*!/*/}
        {/*  /!*                 onChange={(ev) => handleProductChange(ev, index)}*!/*/}
        {/*  /!*                 sx={{width: '100%', mx: '1rem'}} inputProps={{step: "2"}}*!/*/}
        {/*  /!*                 error={errors?.[index]?.['quantity']}*!/*/}
        {/*  /!*                 helperText={errors?.[index]?.['quantity']}*!/*/}
        {/*  /!*      />*!/*/}
        {/*  /!*      <TextField name='weight' label='Greutate' value={product.weight}*!/*/}
        {/*  /!*                 onChange={(ev) => handleProductChange(ev, index)}*!/*/}
        {/*  /!*                 inputProps={{step: "2"}}*!/*/}
        {/*  /!*                 sx={{width: '100%', mx: '1rem'}} error={errors?.[index]?.['weight']}*!/*/}
        {/*  /!*                 helperText={errors?.[index]?.['weight']}/>*!/*/}
        {/*  /!*      <TextField name='unit_of_measure' label='Unitate de masura' value={product.unit_of_measure}*!/*/}
        {/*  /!*                 onChange={(ev) => handleProductChange(ev, index)}*!/*/}
        {/*  /!*                 sx={{width: '100%', mx: '1rem'}}/>*!/*/}
        {/*  /!*    </Box>*!/*/}
        {/*  */}
        {/*  /!*  </Box>*!/*/}
        {/*  /!*})}*!/*/}
        {/*</Box>*/}

      </Box>
      <Button variant='contained' sx={{width: '100%'}} type='submit'>Submit</Button>
    </form>
  )
}

export default CreateOrderDetailsForm;